import moment from "moment";
// import GJV from "geojson-validation";

const COLOR_DARK_ORANGE = "#FF8C00";

export const FILTER_DATE = {
  from: moment().subtract(2, "week"),
  to: moment().add(1, "day")
}

export const GEOJSON_STYLE = {
  color: COLOR_DARK_ORANGE,
  weight: 4,
  opacity: 0.65
};

export const GEOJSON_MARKER_POINT = {
  radius: 8,
  fillColor: COLOR_DARK_ORANGE,
  color: "#000",
  weight: 1,
  opacity: 1,
  fillOpacity: 0.8
};

export const MAP_PARAMS = {
  center: [23.505, 1.09],
  zoom: 3
};

export const BASE_LAYER = {
  // url: "http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png",
  url: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
  maxZoom: 19,
  attribution:
    '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap -Humanitarian OpenStreetMap Team</a>'
};

export const STATUS_COLOR = { UN: "#FFF", AP: "#90EE90", DI: "#FFA07A" };

export const STATUS_DEF = {
  UN: "unassigned",
  AP: "approved",
  DI: "deleted"
};

// export const DEFAULT_SYSTEM_PARAMETER = [];

export const COLOR_RULES = {
  affected: { green: 10000, red: 50000 },
  death: { green: 50, red: 100 }
};

export const isNumeric = string => {
  return /^\d+$/.test(string);
};

export const tableFeature = featureProperties => {
  const p = featureProperties;
  let table = "<table>";
  for (let f in p) {
    table += "<tr><td><small>";
    table += f;
    table += "</small></td><td><small>";
    if (f === "system_parameter") {
      for (let s in p[f]) {
        table += "<div><small>";
        table += JSON.stringify(p[f][s]);
        table += "</small></div>";
      }
    } else {
      table += JSON.stringify(p[f]);
    }
    table += "</small></td></tr>";
  }
  table += "</table>";
  return table;
};

export const updateGroupedInfo = (info, mapData) => {
  let groupedInfos = {};
  Object.keys(mapData).forEach(continent => {
    let c = {};
    Object.keys(mapData[continent]).forEach(country => {
      const infos = mapData[continent][country];
      const newInfos = [];
      infos.forEach(i => {
        if (i.identifier === info.identifier) {
          newInfos.push(info);
        } else {
          newInfos.push(i);
        }
      });
      c[country] = newInfos;
    });
    groupedInfos[continent] = c;
  });
  return groupedInfos;
};

export const setOsmAreas = osmResults => {
  let areas = [];
  try {
    return osmResults.map(osm => {
      return {
        geom: osm.item.hasOwnProperty("geom") ? osm.item.geom : null,
        description: osm.item.display_name,
        osm_id: `${osm.item.osm_type.slice(0, 1).toUpperCase()}${
          osm.item.osm_id
        }`,
        osm_address: osm.item.address
      };
    });
  } catch (err) {
    console.log("setOsmAreas....", err);
    return areas;
  }
};

export const setOsmSingleArea = item => {
  try {
    return {
      description: item.display_name,
      osm_id: `${item.osm_type.slice(0, 1).toUpperCase()}${item.osm_id}`,
      osm_address: item.address,
      geom: item.hasOwnProperty("geom") ? item.geom : null
    };
  } catch (err) {
    console.log("setOsmSingleArea....", err);
    return {};
  }
};


function getToday() {
  const d = new Date()
  return d.toISOString().split('T')[0]
}

export const updateHistory = (history = {}, data = {}, type = "update") => {
  history = history==null ? {} : history
  const kdate = getToday()
  // empty
  if (Object.keys(history).length === 0) return { [kdate]: { [type]: [data] } }
  // key date not found 
  if (history[kdate] === undefined) {
      history[kdate] = { [type]: [data] }
      return history
  }
  // type not found
  if (history[kdate][type] === undefined) {
      // console.log("type not found...", data)
      history[kdate] = { ...history[kdate], [type]: [data] }
      return history
  }
  // add data to keydate and key type
  // console.log("add data..", data)
  const items = history[kdate][type]
  history[kdate][type] = [...items, data]
  return history
}

export function utcStringDate(str) {
  console.log("date is ", str)
  return moment.utc(str).format()
} 

/*
export const geojsonValidate = (coord, prop) => {
  const coordinates = checkCoordinates(coord)
  
  const validFeatureCollection = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: coordinates
        },
        properties: prop
      }
    ]
  };
  if (GJV.valid(validFeatureCollection)) {
    return validFeatureCollection;
  } else {
    return null;
  }
};

*/
