import React from "react";
import PropTypes from "prop-types";
// material ui core
import { withStyles } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
// material ui icons
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
// import SaveIcon from "@material-ui/icons/Save";
// custom components
import EditSimpleSelect from "../../EditSimpleSelect";
import EditMultipleSelect from "../../EditMultipleSelect";
import EditSystemParameters from "../../EditSystemParameters";
import TreeMultipleSelect from "../../TreeMultipleSelect";
import { patchInfo } from "../../../api/api.js";

const styles = theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
    /* width: 450 */
  },
  colHelper: {
    textAlign: "center"
  }
});

class DialogEditInfo extends React.Component {
  state = {
    event: "",
    headline: "",
    description: "",
    web: "",
    categories: "",
    urgency: "",
    severity: "",
    certainty: "",
    info: {},
    patchErrors: null,
    hazard_classifications: "",
    treeSelectData: []
  };

/*****Hazard Tree *******/
  
  buildTreeFromArray = (data) => {
    let idToNodeMap = data.reduce(function (map, node) {
      map[node.value] = node;
      return map;
    }, {});
    let root = []; //Initially set our loop to null

    //loop over data
    data.forEach(function (datum) {

      //each node will have children, so let's give it a "children" poperty
      datum.children = [];

      //add an entry for this node to the map so that any future children can
      //lookup the parent
      idToNodeMap[datum._id] = datum;

      //Does this node have a parent?
      if (!datum.parent) {
        //Doesn't look like it, so this node is the root of the tree
        root.push(datum);
      } else {
        //This node has a parent, so let's look it up using the id
        let parentNode = idToNodeMap[datum.parent];

        //Let's add the current node as a child of the parent node.
        parentNode.children.push(datum);
      }
    });
    return root;
  };


  UNSAFE_componentWillMount() {
    const event = this.props.info.event || "";
    const headline = this.props.info.headline || "";
    const description = this.props.info.description || "";
    const web = this.props.info.web || "";
    const categories = this.props.info.categories;
    const urgency = this.props.info.urgency;
    const severity = this.props.info.severity;
    const certainty = this.props.info.certainty;
    const hazards = this.props.allData.info[10].map(info => ({ ...info, value: info.id, label: info.name, code: info.name }));
    const hazard_classifications = this.props.info.hazard_classifications && this.props.info.hazard_classifications.map(hazard => ({ ...hazard, code: hazard.name, value: hazard.name }));
    const treeSelectData = this.buildTreeFromArray(hazards);
    console.log("hazard ", hazard_classifications);
    this.setState({
      event,
      headline,
      description,
      web,
      categories,
      urgency,
      severity,
      certainty,
      hazard_classifications,
      treeSelectData
    });
  }

  handleChange = name => event => {
    const info = { ...this.state.info };
    info[name] = event.target.value;
    this.setState({
      [name]: event.target.value,
      info
    });
    // rifare in questo componente
    // this.props.updateInfo(info);
  };

  handleComboIds = (ids, field) => {
    const info = { ...this.state.info };
    info[`${field}_id`] = ids;
    this.setState({ info }, () => this.handleOnBlur());
  };

  LIST_COLORS = {
    "1": "#93B459",
    "2": "#5e84b2",
    "3": "#B14D4A",
    "4": "#F5BA31",
    "5": "#EA863E",
    "6": "#7f6696",
    "8": "#D5D5CF"
  };


  render() {
    let { classes, actions, allData, user, info } = this.props;
    const legends = allData.info[8];
    const hazards = allData.info[10].map(info => ({ ...info, code: info.name, value: info.name }));
    return (
      <DialogContent>
        <Paper style={{ marginTop: 5 }}>
          <Grid container alignItems="center">
            <Grid item xs={11}>
              <TextField
                fullWidth
                id="event"
                label="Event"
                className={classes.textField}
                variant="outlined"
                multiline
                rows="2"
                value={this.state.event}
                onChange={this.handleChange("event")}
                onBlur={this.handleOnBlur}
                margin="normal"
              />
            </Grid>
            <Grid item xs={1} className={classes.colHelper}>
              <Tooltip title={legends.event}>
                <LiveHelpIcon color="primary" />
              </Tooltip>
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                id="headline"
                label="Headline"
                className={classes.textField}
                variant="outlined"
                multiline
                rows="3"
                value={this.state.headline}
                onChange={this.handleChange("headline")}
                onBlur={this.handleOnBlur}
                margin="normal"
              />
            </Grid>
            <Grid item xs={1} className={classes.colHelper}>
              <Tooltip
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: legends.headline
                    }}
                  />
                }
              >
                <LiveHelpIcon color="primary" />
              </Tooltip>
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                id="description"
                label="Description"
                className={classes.textField}
                variant="outlined"
                multiline
                rows="3"
                value={this.state.description}
                onChange={this.handleChange("description")}
                onBlur={this.handleOnBlur}
                margin="normal"
              />
            </Grid>
            <Grid item xs={1} className={classes.colHelper}>
              <Tooltip title={legends.description}>
                <LiveHelpIcon color="primary" />
              </Tooltip>
            </Grid>
            <Grid item xs={11}>
              <TextField
                fullWidth
                id="web"
                label="Web"
                className={classes.textField}
                variant="outlined"
                multiline
                rows="2"
                value={this.state.web}
                onBlur={this.handleOnBlur}
                onChange={this.handleChange("web")}
                margin="normal"
              />
            </Grid>
            <Grid item xs={1} className={classes.colHelper}>
              <Tooltip title={legends.web}>
                <LiveHelpIcon color="primary" />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} style={{ marginLeft: 10 }}>
              <EditMultipleSelect
                label="categories"
                field="categories"
                current={this.state.categories}
                values={allData.info[0]}
                getIds={this.handleComboIds}
              />
            </Grid>
            <Grid item xs={1} className={classes.colHelper}>
              <Tooltip title={legends.categories}>
                <LiveHelpIcon color="primary" />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} style={{ marginLeft: 10 }}>
              <EditSimpleSelect
                label="urgency"
                field="urgency"
                current={this.state.urgency}
                values={allData.info[2]}
                getIds={this.handleComboIds}
              />
            </Grid>
            <Grid item xs={1} className={classes.colHelper}>
              <Tooltip title={legends.urgency}>
                <LiveHelpIcon color="primary" />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} style={{ marginLeft: 10 }}>
              <EditSimpleSelect
                label="severity"
                field="severity"
                current={this.state.severity}
                values={allData.info[3]}
                getIds={this.handleComboIds}
              />
            </Grid>
            <Grid item xs={1} className={classes.colHelper}>
              <Tooltip title={legends.severity}>
                <LiveHelpIcon color="primary" />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} style={{ marginLeft: 10 }}>
              <EditSimpleSelect
                label="certainty"
                field="certainty"
                current={this.state.certainty}
                values={allData.info[4]}
                getIds={this.handleComboIds}
              />
            </Grid>
            <Grid item xs={1} className={classes.colHelper}>
              <Tooltip title={legends.certainty}>
                <LiveHelpIcon color="primary" />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6} style={{ marginLeft: 10 }}>
              <TreeMultipleSelect
                label="hazard classifications"
                field="hazard_classifications"
                tree={this.state.treeSelectData}
                flatArray={hazards}
                idList={this.state.hazard_classifications.map(item => item.id)}
                getIds={this.handleComboIds}
                colors={this.LIST_COLORS}
              />
            </Grid>
            <Grid item xs={1} className={classes.colHelper}>
              <Tooltip title={legends.hazard_classifications}>
                <LiveHelpIcon color="primary" />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {this.state.patchErrors !== null && (
                <Typography color="error" variant="h6">
                  {JSON.stringify(this.state.patchErrors)}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>

        <Grid container>
          <Grid item xs={12}>
            <div style={{ marginTop: 10, padding: 10 }}>
              {info.hasOwnProperty("resources") && (
                <div>
                  <Typography variant="h6">Resources</Typography>
                  {info.resources.map((r, idx) => (
                    <div key={`dialog-resources-${idx}`} style={{ padding: 5 }}>
                      <a href={r.uri} target="_blank" rel="noopener noreferrer">
                        <Typography variant="body1">{r.uri}</Typography>
                      </a>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <EditSystemParameters
              actions={actions}
              info={this.props.info}
              allData={allData}
              user={user}
            />
          </Grid>
        </Grid>
      </DialogContent>
    );
  }

  handleOnBlur = _ => {
    this.handleBtnUpdate();
  };

  handleBtnUpdate = async _ => {
    if (Object.keys(this.state.info).length > 0) {
      const response = await patchInfo(
        this.props.user,
        this.props.info.identifier,
        this.state.info
      );
      if (response.hasOwnProperty("identifier")) {
        this.props.actions.reloadInfo(this.props.info.identifier);
        if (this.state.patchErrors !== null) {
          this.setState({ patchErrors: null });
        }
      } else {
        this.setState({ patchErrors: response });
      }
    }
  };
}

DialogEditInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  info: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired
};

export default withStyles(styles)(DialogEditInfo);
