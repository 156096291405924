import React, { Component } from "react";
import PropTypes from "prop-types";
import DateRangeIcon from "@material-ui/icons/DateRange";
// import { DatePicker } from "material-ui-pickers";
import { DatePicker } from "@material-ui/pickers";

import { IconButton, InputAdornment } from "@material-ui/core";
import moment from "moment";

class InputDatePicker extends Component {
  state = { value: "" };

  UNSAFE_componentWillMount() {
    const value =
      this.props.currentDate !== null ? this.props.currentDate : moment();
    this.setState({ value });
  }

  handleDateChange = date => {
    // console.log(date.toISOString());
    const d = date || "";
    this.setState({ value: d });
    this.props.setdate(this.props.label, d);
  };

  render() {
    let {label, style} = {...this.props};
    console.log("result...", label, style)
    if (!style) style = {maxWidth: 175}
    return (
      <DatePicker
        autoOk
        clearable
        variant="outlined"
        style={style}
        label={label}
        value={this.state.value}
        onChange={this.handleDateChange}
        animateYearScrolling={false}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  }
}

InputDatePicker.propTypes = {
  // classes: PropTypes.object.isRequired,
  // actions: PropTypes.object.isRequired,
  currentDate: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  label: PropTypes.string.isRequired
};

export default InputDatePicker;
