// rest-auth
export const API_AUTH_LOGIN = "/rest-auth/login/";
// API LEGENDS
export const API_LEGENDS_INFO = "/legends/?m=info";
// API_AREA_CONTINENTS
export const API_AREA_CONTINENTS = "/area/continents/";
export const API_AREA_COUNTRIES = "/area/countries/";
export const API_AREA_REGIONS = "/area/regions/";
// alerts
export const API_ALERTS = "/alerts/";
// temp lav export const API_ALERTS_UN = "/alerts/?infos__status=UN";
export const API_ALERTS_UN = "/infos/?status=UN";
export const API_INFOS_GROUPED = "/infos/grouped-by-regions/"; //?status=UN
// infos
export const API_INFOS = "/infos/"; // [GET, POST, PATCH]
export const API_INFO_CATEGORIES = "/infos/categories/";
export const API_INFO_RESPONSE_TYPE = "/infos/response-types/";
export const API_INFO_URGENCIES = "/infos/urgencies/";
export const API_INFO_SEVERITIES = "/infos/severities/";
export const API_INFO_CERTAINTIES = "/infos/certainties/";
export const API_INFO_RESOURCES = "/infos/resources/";
export const API_INFO_STATUS = "/infos/status/";
export const API_INFO_GDACS = "/infos/gdacs-severities/";
export const API_INFO_HAZARD = "/infos/hazard-classifications/";
export const API_INFO_SYSTEM_PARAMETERS = "/infos/system-parameters/";
export const API_INFO_UNITS = "/infos/units/";
export const API_ALL_INFO = [
  API_INFO_CATEGORIES,
  API_INFO_RESPONSE_TYPE,
  API_INFO_URGENCIES,
  API_INFO_SEVERITIES,
  API_INFO_CERTAINTIES,
  API_INFO_GDACS,
  API_INFO_STATUS,
  API_INFO_SYSTEM_PARAMETERS,
  API_LEGENDS_INFO,
  API_INFO_UNITS,
  API_INFO_HAZARD
];

// admin
export const API_ADMIN_CACHE = "/admin/cache/";

export const API_INFO_TO_REVIEW = '/infos/reviews/';
export const API_INFO_REVIEWS = '/reviews/';
