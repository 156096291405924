import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
// import Paper from "@material-ui/core/Paper";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";

import InfoIcon from "@material-ui/icons/Info";
// import AddIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteForever";
// import SaveAltIcon from "@material-ui/icons/AddCircle";
import blue from "@material-ui/core/colors/blue";
import yellow from "@material-ui/core/colors/yellow";
import red from "@material-ui/core/colors/red";
// import green from "@material-ui/core/colors/green";

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import InputDatePicker from "../InputDatePicker";

import moment from "moment";

const MOMENT_FORMAT = "MMMM Do YYYY";

function ShowInfoReview({ info }) {
  const fields = Object.keys(info)
  return (
    <Table>
      <TableBody>
        {fields.map(field =>
          <TableRow key={`info-field-${field}`}>
            <TableCell>{field}</TableCell><TableCell>{info[field]}</TableCell>
          </TableRow>)}
      </TableBody>
    </Table>
  )
}

function EditInputDate({ textdate, label }) {
  return (
    <InputDatePicker
      setdate={() => console.log("set date....")}
      currentDate={textdate}
      label={label}
    />
  )
}

function ComboParams({ allData }) {
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Select value={value}
      onChange={handleChange}>
      {allData.info[7].map((p, idx) =>
        <MenuItem key={`combo-param-${idx}`} value={p.value_name}>
          {p.value_name}
        </MenuItem>)}
    </Select>
  )
}


function ListParamRow({ param, allData, info, handleEdit, idx }) {
  const [visible, setVisible] = React.useState(true);
  const [edit, setEdit] = React.useState(false)
  const [q, setQ] = React.useState(param.quantitative)
  const [isAnUpdate, setIsAnUpdate] = React.useState(false)
  React.useEffect(() => {
    const { id } = { ...param.extra };
    if (id) {
      const ids = info.parameters.map(p => {
        const { id } = { ...p.extra }
        return id
      })
      if (ids.includes(id)) {
        setIsAnUpdate(true)
      }
    }
  }, [info])

  return (
    <TableRow style={{ backgroundColor: isAnUpdate ? "#ffffcc" : "#fff" }}>
      <TableCell style={{ textAlign: "center" }}>
        <Tooltip
          title={`${JSON.stringify(param.extra)}`}
          placement="top"
        >
          {isAnUpdate ?
            <InfoIcon style={{ color: red[500] }} />
            :
            <InfoIcon style={{ color: blue[500] }} />
          }
        </Tooltip>
      </TableCell>
      <TableCell>
        {edit && <ComboParams allData={allData} />}
        {visible && !edit &&
          allData.info[7]
            .filter((p) => p.id === Number(param.system_parameter_id))
            .map((p) => p.value_name)[0]}
      </TableCell>
      <TableCell>{visible && edit ? <div style={{ maxWidth: 80 }}><TextField
        type="number"
        value={q}
        onChange={(evt) => setQ(evt.target.value)}
        margin="normal"
        helperText="quantitative"
      /></div> : param.quantitative}</TableCell>
      <TableCell>
        {edit && <EditInputDate textdate={param.onset} label="" />}
        {visible && !edit && moment(param.onset).format(MOMENT_FORMAT)}
      </TableCell>
      <TableCell>
        {edit && <EditInputDate textdate={param.expires} label="" />}
        {visible && !edit && moment(param.expires).format(MOMENT_FORMAT)}
      </TableCell>
      <TableCell>
        {edit && <EditInputDate textdate={param.effective} label="" />}
        {visible && !edit && moment(param.effective).format(MOMENT_FORMAT)}
      </TableCell>
      <TableCell style={{ maxWidth: 200 }}>{param.description}</TableCell>
      {/*
      <TableCell>
        {edit ? <Button onClick={() => setEdit(false)} variant="outlined" color="primary" style={{ maxWidth: 55 }}>close</Button> :
          <Tooltip title="remove params">
            <DeleteIcon
              style={{ color: red[700] }}
              onClick={() => setVisible(!visible)}
            />
          </Tooltip>
        }
        {edit ? <Button onClick={saveData} variant="outlined" color="secondary">save</Button> :
          <Tooltip title="edit params">
            <EditIcon onClick={() => setEdit(!edit)} style={{ color: yellow[700], marginLeft: 5 }} />
          </Tooltip>
        }
      </TableCell>
      */}
    </TableRow>
  );

  function saveData() {
    setEdit(false)
    handleEdit(idx, q)
  }
}

function ListReview({ info, infoReview, actions, allData, idxRow, updateOrDiscard }) {
  const [params, setParams] = React.useState([]);

  React.useEffect(() => {
    if (infoReview.values.hasOwnProperty("parameters")) {
      setParams(infoReview.values.parameters);
    }
  }, [infoReview]);
  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          updated on: {moment(infoReview.modified).format(MOMENT_FORMAT)}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <div style={{ textAlign: "center" }}>
                  <Button onClick={() => updateOrDiscard(idxRow, "update")} variant="outlined" color="secondary">
                    UPDATE ALL
                  </Button>
                  <Button
                    onClick={() => updateOrDiscard(idxRow, "discard")}
                    variant="outlined"
                    color="secondary"
                    style={{ marginLeft: 5 }}
                  >
                    DISCARD ALL
                  </Button>
                </div>
              </TableCell>
            </TableRow>
            {params.length > 0 &&
              < TableRow >
                <TableCell>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>name</TableCell>
                        <TableCell>value</TableCell>
                        <TableCell>onset</TableCell>
                        <TableCell>expires</TableCell>
                        <TableCell>effective</TableCell>
                        <TableCell>location</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {params.map((param, idx) => (
                        <ListParamRow
                          key={`params-upd-${idx}`}
                          param={param}
                          allData={allData}
                          info={info}
                          handleEdit={handleEdit}
                          idx={idx}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            }
            {infoReview.values.resources.length > 0 &&
              <TableRow>
                <TableCell>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Resources</TableCell>
                        <TableCell>
                          {infoReview.values.resources.map((r, idx) => (
                            <div key={`r-${idx}`}>{r.uri}</div>
                          ))}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            }
            <TableRow>
              <TableCell>
                {infoReview.values.hasOwnProperty("info") && <ShowInfoReview info={infoReview.values.info} />}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </ExpansionPanelDetails>
    </ExpansionPanel >
  );

  function handleEdit(idx, q) {
    const updated = params.map((p, i) => {
      if (i === idx) {
        p.quantitative = q
      }
      return p
    })
    setParams(updated)
  }
}

export default function CompareParameters({ info, updated, actions, allData }) {
  const [rewInfo, setRewInfo] = React.useState([])

  React.useEffect(() => {
    setRewInfo(updated)
  }, [updated])
  return (
    <div>
      {rewInfo.map((u, idx) => (
        <ListReview
          key={`review-${idx}`}
          info={info}
          infoReview={u}
          actions={actions}
          allData={allData}
          idxRow={idx}
          updateOrDiscard={updateOrDiscard}
        />
      ))}
    </div>
  );

  function updateOrDiscard(idxRow, type) {
    const updated = rewInfo.filter((r, idx) => idx === idxRow)[0]
    const l = rewInfo.filter((r, idx) => idx !== idxRow)
    setRewInfo(l)
    actions.updateInfoToValidate(info, updated, type)
  }
}

/**
function ListParam({ param, type, selBtn, allData }) {
  const [isSel, setSel] = React.useState(false);
  let param_name = "none";
  const { id, notes, lon, lat } = { ...param.extra };
  if (!id) return null;
  try {
    if (type === "current") {
      param_name = allData.info[7]
        .filter((p) => p.id === Number(param.system_parameter.id))
        .map((p) => p.value_name)[0];
    } else {
      param_name = allData.info[7]
        .filter((p) => p.id === Number(param.system_parameter_id))
        .map((p) => p.value_name)[0];
    }
  } catch (err) {
    console.log("errrr...");
  }
  return (
    <Paper style={{ marginBottom: 30, padding: 5, width: 550 }}>
      <div>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>name</TableCell>
              <TableCell>{param_name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>description</TableCell>
              <TableCell>{param.description}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>value</TableCell>
              <TableCell>{param.quantitative}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>onset</TableCell>
              <TableCell>{param.onset}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>expires</TableCell>
              <TableCell>{param.expires}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>effective</TableCell>
              <TableCell>{param.effective}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell>{id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>notes</TableCell>
              <TableCell>{notes}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>lon/lat</TableCell>
              <TableCell>
                {lon}/{lat}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <div style={{ marginTop: 5 }}>
        <Button
          size="small"
          variant="outlined"
          color={type === "current" ? "primary" : "secondary"}
          onClick={handleSel}
          disabled={param.hasOwnProperty("elaborated") ? true : false}
        >
          {type === "current" ? "remove" : "add"}
          {isSel === true && ", Done!"}
        </Button>{" "}
        {type !== "current" && (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={gotoPage}
          >
            view map
          </Button>
        )}
      </div>
    </Paper>
  );

  function handleSel() {
    setSel(!isSel);
    selBtn(type, param);
  }

  function gotoPage() {
    window.open(
      `https://www.openstreetmap.org/search?query=${param.areas[0].description}`,
      "_blank"
    );
  }
}
*/

/**
function OLDCompareParameters({ info, updated, actions, allData }) {
  const last_update = updated[0].modified;
  const upd_parameters = updated[0].values.parameters;
  const newparams = getNewParams(info, upd_parameters);
  const { content } = { ...updated[0].values.info };
  return (
    <ExpansionPanel defaultExpanded={true}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">
          updated on: {moment(last_update).format(MOMENT_FORMAT)}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Paper style={{ width: "100%" }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Approved Parameters</TableCell>
                <TableCell>New Parameters</TableCell>
              </TableRow>
              <TableRow style={{ verticalAlign: "top" }}>
                <TableCell>
                  {info.parameters.length < 1 && (
                    <div>parameters not present</div>
                  )}
                  {info.parameters.map((p, idx) => (
                    <ListParam
                      key={`current-param-${idx}`}
                      param={p}
                      type="current"
                      selBtn={selBtn}
                      allData={allData}
                    />
                  ))}
                </TableCell>
                <TableCell>
                  {newparams.map((p, idx) => (
                    <ListParam
                      key={`new-param-${idx}`}
                      param={p}
                      type="new"
                      selBtn={selBtn}
                      allData={allData}
                    />
                  ))}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Resources</TableCell>
                <TableCell>
                  <Typography variant="h6">new Resources</Typography>
                </TableCell>
              </TableRow>
              {updated[0].values.resources.length > 0 && (
                <TableRow>
                  <TableCell>
                    {info.resources.map((r, idx) => (
                      <div key={`r-${idx}`}>{r.uri}</div>
                    ))}
                  </TableCell>
                  <TableCell>
                    {updated[0].values.resources.map((r, idx) => (
                      <div key={`r-${idx}`}>{r.uri}</div>
                    ))}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>
                  <Typography variant="h6">new Description</Typography>
                </TableCell>
              </TableRow>

              {Object.keys(updated[0].values.info).length > 0 && (
                <TableRow style={{ verticalAlign: "top" }}>
                  <TableCell>
                    <div
                      dangerouslySetInnerHTML={{ __html: info.description }}
                    />
                  </TableCell>
                  <TableCell>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell colSpan={2}>
                  <Button
                    onClick={setReviewd}
                    variant="contained"
                    color="default"
                  >
                    UPDATE INFO
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );

  function selBtn(type, param) {
    if (type === "current") {
      actions.removeParamtoInfo(info, param);
    } else {
      actions.addParamtoInfo(info, param);
    }
    param["elaborated"] = true;
  }

  function setReviewd() {
    actions.updateInfoToValidate(info, updated);
  }

  function getNewParams(info, updated) {
    const all = info.parameters.map((p) => p.description).join();
    let newparams = [];
    updated.forEach((f) => {
      if (!all.includes(f.description)) {
        newparams.push(f);
      }
    });
    return newparams;
  }
}

*/
