import React from "react";
import PropTypes from "prop-types";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import SystemParameterTable from "../../SystemParameterTable";
import moment from "moment";

const MOMENT_FORMAT = "MMMM Do YYYY, h:mm a";

class DialogDataInfo extends React.Component {
  render() {
    const { info, allData } = this.props;
    const dateFields = ["onset", "expires", "effective"];
    const legends = allData.info[8];
    return (
      <DialogContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>gdacs severity</TableCell>
              <TableCell>
                {info.gdacs_severity === null ? (
                  "not set yet"
                ) : (
                  <div>
                    {info.gdacs_severity.name} - {info.gdacs_severity.value}
                  </div>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title={legends.regions}>
                  <Typography>regions</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                {info.regions.map((r, idx) => (
                  <div key={`r-${r.id}-${idx}`}>
                    {r.name} - {r.country.name} {r.country.continent.name}
                  </div>
                ))}
                {info.countries.map(c => c.name).join()}
              </TableCell>
            </TableRow>
            {dateFields.map((f, idx) => (
              <TableRow key={`date-field-${idx}`}>
                <TableCell>
                  <Tooltip
                    title={
                      <div dangerouslySetInnerHTML={{ __html: legends[f] }} />
                    }
                  >
                    <Typography>{f}</Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {info[f] !== null && moment(info[f]).format(MOMENT_FORMAT)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <Tooltip title={legends.categories}>
                  <Typography>categories</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                {info.categories.map((c, idx) => (
                  <div key={`categ-field-${idx}`}>
                    {c.name}: {c.value}
                  </div>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title={legends.urgency}>
                  <Typography>urgency</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <div>
                  {info.urgency.name}: {info.urgency.value}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title={legends.severity}>
                  <Typography>severity</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <div>
                  {info.severity.name}: {info.severity.value}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title={legends.certainty}>
                  <Typography>certainty</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <div>
                  {info.certainty.name}: {info.certainty.value}
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title={legends.hazard_classifications}>
                  <Typography>Hazard Classifications</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                {info.hazard_classifications.map((c, idx) => (
                  <div key={`hazard-field-${idx}`}>
                    <strong>{c.name}: </strong> {c.description}
                  </div>
                ))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title={legends.headline}>
                  <Typography>headline</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>{info.headline}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Tooltip title={legends.description}>
                  <Typography>description</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <div dangerouslySetInnerHTML={{ __html: info.description }} />
              </TableCell>
            </TableRow>
            {info.hasOwnProperty("resources") && (
              <TableRow>
                <TableCell>resources</TableCell>
                <TableCell>
                  {info.resources.map((r, idx) => (
                    <div key={`dialog-resources-${idx}`}>
                      <a href={r.uri} target="_blank" rel="noopener noreferrer">
                        {r.uri}
                      </a>
                    </div>
                  ))}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <Tooltip title={legends.web}>
                  <Typography>web</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>{info.web}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <SystemParameterTable info={info} />
      </DialogContent>
    );
  }
}

DialogDataInfo.propTypes = {
  info: PropTypes.object.isRequired,
  allData: PropTypes.object.isRequired
};

export default DialogDataInfo;
