import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 280
    }
  }
};

class MultipleSelect extends React.Component {
  state = {
    name: [],
    backgroundColors: []
  };

  componentDidMount() {
    this.props.values.results.map(value => value.id).reduce((previous, current) => this.getBackgroundColor(this.props.colors, previous, current), 0);
  }

  handleChange = event => {
    this.setState({ name: event.target.value });
    const ids = this.props.values.results
      .filter(value => event.target.value.includes(value.code))
      .map(value => value.id);
    this.props.getIds(ids, this.props.field);
  };

  getBackgroundColor = (colors, previousIndex, id) => {
    if (!colors) {
      return "white";
    }
    let backgroundColors = this.state.backgroundColors;
    if (colors[id]) {
      backgroundColors[id] = colors[id];
    } else {
      if (previousIndex) {
        backgroundColors[id] = backgroundColors[previousIndex];
      } else {
        backgroundColors[id] = "white";
      }
    }
    this.setState({ backgroundColors: backgroundColors });
    return id;
  }

  render() {
    const { classes, label, values, colors } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="select-multiple-checkbox">{label}</InputLabel>
        <Select
          multiple
          value={this.state.name}
          onChange={this.handleChange}
          input={<Input id="select-multiple-checkbox" />}
          renderValue={selected => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {values.results.map(name => (
            <MenuItem key={name.id} value={name.code} style={{ "backgroundColor": this.state.backgroundColors[name.id] }}>
              <Checkbox checked={this.state.name.indexOf(name.code) > -1} />
              <ListItemText primary={name.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }



}

MultipleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  getIds: PropTypes.func.isRequired
};

export default withStyles(styles)(MultipleSelect);
