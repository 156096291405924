import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  Tooltip
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/PlaylistAdd";

import { searchInfos } from "../../api/api.js";

function InfoAdd({ actions, user }) {
  const [open, setOpen] = React.useState(true);
  const [event, setEvent] = React.useState("");
  const [infos, setInfos] = React.useState({
    count: 0,
    results: [],
    previous: null,
    next: null
  });
  const [label, setLabel] = React.useState("");

  function handleClose() {
    setOpen(false);
    actions.toggleInfoAdd();
  }

  function handleChangeEvent(evt) {
    setEvent(evt.target.value);
  }

  function handleSearch() {
    const q = `?event__icontains=${event}`;
    apiSearch(q);
  }

  function handleNavigate(page) {
    const s = infos[page].split("?")[1];
    apiSearch(`?${s}`);
  }

  const apiSearch = async q => {
    const response = await searchInfos(user, q);
    setInfos(response);
    if (response.hasOwnProperty("count")) {
      response.count > 0
        ? setLabel(`found: ${response.count}`)
        : setLabel("data non found");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="add-new-info"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Add info to alert</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              id="event"
              label="Event"
              fullWidth
              value={event}
              helperText="contains"
              onChange={handleChangeEvent}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button onClick={handleSearch} variant="outlined" color="primary">
              search
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography>{label}</Typography>
            <List component="nav" aria-label="Info list" dense>
              {infos.results.map((i, idx) => (
                <ListItem
                  key={`ii-${i.identifier}-${idx}`}
                  style={{ padding: 3 }}
                >
                  <ListItemText primary={i.event} secondary={i.headline} />
                  <Tooltip title="add info">
                    <IconButton
                      onClick={() => actions.addInfoToAlert(i)}
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleNavigate("previous")}
          variant="outlined"
          color="primary"
          disabled={infos.previous !== null ? false : true}
        >
          previous
        </Button>
        <Button
          onClick={() => handleNavigate("next")}
          variant="outlined"
          color="primary"
          disabled={infos.next !== null ? false : true}
        >
          next
        </Button>
        <Button onClick={handleClose} variant="outlined" color="secondary">
          close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InfoAdd;
