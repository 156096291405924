import React, { Component } from "react";
// import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Collapse,
  IconButton,
  InputAdornment,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { deepOrange, deepPurple, red } from "@material-ui/core/colors";
import { DatePicker } from "@material-ui/pickers";

// icons
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import PlusOneIcon from "@material-ui/icons/PlusOne";
import SaveIcon from "@material-ui/icons/Save";
import DateRangeIcon from "@material-ui/icons/DateRange";
import RefreshIcon from "@material-ui/icons/Refresh";

import moment from "moment";

import InfoAdd from "./InfoAdd.js";
import InfoTable from "../Infos/InfoTable.js";

import { getAlerts, patchAlert } from "../../api/api.js";

const MOMENT_FORMAT = "MMMM Do YYYY";

const styles = theme => ({
  orangeAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepOrange[500]
  },
  purpleAvatar: {
    margin: 10,
    color: "#fff",
    backgroundColor: deepPurple[500]
  },
  btnDelete: {
    color: red[500]
  }
});

class EditAlert extends Component {
  state = { title: "", description: "" };

  UNSAFE_componentWillMount() {
    this.setState({
      title: this.props.alert.title,
      description: this.props.alert.description
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
    this.props.setData(name, event.target.value);
  };
  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div>
            <TextField
              fullWidth
              id="title"
              label="Title"
              variant="outlined"
              multiline
              rows="2"
              value={this.state.title}
              onChange={this.handleChange("title")}
              margin="normal"
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>
            <TextField
              fullWidth
              id="description"
              label="Description"
              variant="outlined"
              multiline
              rows="2"
              value={this.state.description}
              onChange={this.handleChange("description")}
              margin="normal"
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

function Info({ classes, info, alertIdentifier, actions, allData }) {
  const [open, setOpen] = React.useState(false);
  function handleClick() {
    setOpen(!open);
  }

  return (
    <React.Fragment>
      <ListItem onClick={handleClick}>
        <ListItemAvatar>
          <Avatar className={classes.orangeAvatar}>I</Avatar>
        </ListItemAvatar>
        <ListItemText primary={info.event} secondary={info.headline} />
        <IconButton
          onClick={() =>
            actions.deleteInfoFromAlert(alertIdentifier, info.identifier)
          }
        >
          <DeleteIcon className={classes.btnDelete} />
        </IconButton>
        <IconButton onClick={handleClick}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <InfoTable
          info={info}
          identifier={info.identifier}
          actions={actions}
          allData={allData}
        />
      </Collapse>
    </React.Fragment>
  );
}

class Alert extends Component {
  state = {
    open: false,
    edit: false,
    title: null,
    description: null,
    event: ""
  };

  handleClick = _ => this.setState({ open: !this.state.open });
  handleEdit = _ => this.setState({ edit: !this.state.edit });
  handleSetText = (field, value) => {
    this.setState({ [field]: value });
  };
  handleUpdate = _ => {
    this.setState({ edit: !this.state.edit });
    const data = {};
    if (this.state.title !== null) data["title"] = this.state.title;
    if (this.state.description !== null)
      data["description"] = this.state.description;
    this.props.actions.updateAlert(this.props.alert.identifier, data);
  };

  handleChangeEvent = evt => this.setState({ event: evt.target.value });

  render() {
    const { classes, alert } = this.props;
    return (
      <React.Fragment>
        <ListItem button onClick={this.handleClick}>
          <ListItemAvatar>
            <Avatar className={classes.purpleAvatar}>A</Avatar>
          </ListItemAvatar>
          <ListItemText primary={alert.title} secondary={alert.description} />
          {this.state.open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.open} timeout="auto" unmountOnExit>
          <Paper>
            <div style={{ marginLeft: 20 }}>
              {this.state.edit && (
                <EditAlert alert={alert} setData={this.handleSetText} />
              )}
              <div style={{ marginBottom: 20 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>identifier</TableCell>
                      <TableCell>onset</TableCell>
                      <TableCell>expires</TableCell>
                      <TableCell>modifed</TableCell>
                      <TableCell>countries</TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{alert.identifier}</TableCell>
                      <TableCell>
                        {moment(alert.onset).format(MOMENT_FORMAT)}
                      </TableCell>
                      <TableCell>
                        {moment(alert.expires).format(MOMENT_FORMAT)}
                      </TableCell>
                      <TableCell>
                        {moment(alert.modified).format(MOMENT_FORMAT)}
                      </TableCell>
                      <TableCell>
                        {alert.countries.map(c => c.name).join(`, `)}
                      </TableCell>
                      <TableCell>
                        {!this.state.edit ? (
                          <Tooltip title="Edit alert">
                            <Button
                              onClick={this.handleEdit}
                              variant="contained"
                              color="primary"
                            >
                              <EditIcon />
                            </Button>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Save alert">
                            <Button
                              onClick={this.handleUpdate}
                              variant="contained"
                              color="primary"
                            >
                              <SaveIcon />
                            </Button>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Add new info" placement="top-start">
                          <Button
                            onClick={() =>
                              this.props.actions.toggleInfoAdd(alert.identifier)
                            }
                            variant="contained"
                            color="primary"
                          >
                            <PlusOneIcon />
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
              <List component="nav" dense>
                {alert.infos.map((info, idx) => (
                  <Info
                    key={`info-${idx}-${info.identifier}`}
                    classes={classes}
                    alertIdentifier={alert.identifier}
                    info={info}
                    actions={this.props.actions}
                    allData={this.props.allData}
                  />
                ))}
              </List>
            </div>
          </Paper>
        </Collapse>
      </React.Fragment>
    );
  }
}

function Alerts({ classes, alerts, user, actions, allData }) {
  return (
    <List component="nav">
      {alerts.results.map((item, idx) => (
        <Alert
          key={`alert-${idx}`}
          classes={classes}
          alert={item}
          user={user}
          actions={actions}
          allData={allData}
        />
      ))}
    </List>
  );
}

class AlertsContainer extends Component {
  state = {
    alerts: { results: [], count: 0 },
    onset: moment().subtract(2, "week"),
    isVisibleModalAddInfo: false,
    currentAlertIdentifier: null
  };

  componentDidMount() {
    this._apiAlerts();
  }

  render() {
    const { classes } = this.props;
    const actions = {
      updateAlert: this.updateAlert,
      gdacs: this.handleGdacs,
      toggleInfoAdd: this.toggleInfoAdd,
      addInfoToAlert: this.addInfoToAlert,
      deleteInfoFromAlert: this.deleteInfoFromAlert
    };
    return (
      <Card>
        <CardContent>
          <Grid container space={2}>
            <Grid item xs={6} />
            <Grid item xs={3}>
              <DatePicker
                autoOk
                clearable
                variant="outlined"
                style={{ width: 175 }}
                label="onset gte"
                value={this.state.onset}
                onChange={this.handleDateOnset}
                animateYearScrolling={false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <DateRangeIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => this._apiAlerts()}
              >
                <RefreshIcon />
                reload
              </Button>
            </Grid>
            <Grid item xs={12}>
              {this.state.alerts.count < 1 && "no data"}
              {this.state.alerts.results.length > 0 && (
                <Alerts
                  classes={classes}
                  alerts={this.state.alerts}
                  user={this.props.user}
                  actions={actions}
                  allData={this.props.allData}
                />
              )}
            </Grid>
          </Grid>
          {this.state.isVisibleModalAddInfo && (
            <InfoAdd actions={actions} user={this.props.user} />
          )}
        </CardContent>
      </Card>
    );
  }

  deleteInfoFromAlert = (alertIdentifier, infoIdentifier) => {
    const results = this.state.alerts.results.map(a => {
      if (a.identifier === alertIdentifier) {
        a.infos = a.infos.filter(i => i.identifier !== infoIdentifier);
      }
      return a;
    });
    this.setState({ alerts: { ...this.state.alerts, results } });
  };

  addInfoToAlert = info => {
    const results = this.state.alerts.results.map(a => {
      if (a.identifier === this.state.currentAlertIdentifier) {
        if (a.infos.findIndex(i => i.identifier === info.identifier) < 0) {
          a.infos = [...a.infos, info];
        }
      }
      return a;
    });
    this.setState({ alerts: { ...this.state.alerts, results } });
  };

  toggleInfoAdd = alertIdentifier => {
    this.setState({
      isVisibleModalAddInfo: !this.state.isVisibleModalAddInfo,
      currentAlertIdentifier: alertIdentifier
    });
  };

  handleGdacs = _ =>
    alert("method not allowed in this section, modify not saved on db");

  handleDateOnset = date => {
    const d = date || "";
    this.setState({ onset: d });
  };

  updateAlert = async (identifier, data) => {
    const response = await patchAlert(this.props.user, identifier, data);
    if (response.hasOwnProperty("identifier")) this._apiAlerts(this.props.user);
  };

  _apiAlerts = async _ => {
    const params = `?onset__gte=${this.state.onset
      .toISOString()
      .substr(0, 19)}`;
    const alerts = await getAlerts(this.props.user, params);
    if (alerts.hasOwnProperty("results")) this.setState({ alerts });
  };
}

export default withStyles(styles)(AlertsContainer);
